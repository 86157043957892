import { Affix, Col, Input, Row, Spin, Tabs } from 'antd';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import React, { useEffect } from 'react';
import { PUBLIC_IMAGE_URL, setLocationHref } from '../../utils';
import { BlankSegment, DynamicProductCard, Footer, FooterLogo, Navigation, NoLackTag, PaginationCus, ProductCard, RightSider, VendorRelated } from '../components';
import EmptyStatus from '../components/empty';
import styles from './index.less';
import Store, { ColorMap } from './store';

const store = new Store();

const VendorDetail: React.FC<unknown> = observer((props) => {
  useEffect(() => {
    setLocationHref();
  }, []);

  const {
    tabs,
    pageLoading,
    paginationStore,
    currentTabKey,
    renderList,
    vendorInfo,
    onTabChange,
    onAffixChange,
    affixed,
    conditions,
    rightSiderStore,
    filters,
    onSearch,
  } = store;
  const tabItems = tabs.map((el) => {
    return {
      key: el.key,
      label: el.title,
      tabKey: el.key,
    };
  });

  const renderPage = (): React.ReactNode => {
    if (currentTabKey === 'style') {
      return (
        <Row gutter={[
          12,
          12,
        ]}
        >
          {
            renderList[currentTabKey]?.styleList.map?.((el) => {
              return (
                <Col key={el.goodsId}>
                  <ProductCard
                    {...el}
                  />
                </Col>
              );
            })
          }
          {
            !renderList[currentTabKey]?.styleList || renderList[currentTabKey]?.styleList?.length === 0 && (
              <div className={styles.emptyDataWrap}>
                <EmptyStatus/>
              </div>
            )
          }
        </Row>
      );
    } else {
      return (
        <div>
          {renderList[currentTabKey].dynamicList?.map((el) => {
            return (
              <VendorRelated
                createdAt={el.createdAt}
                key={el.goodsVendorId}
                listContent={(
                  <Row gutter={[
                    8,
                    8,
                  ]}
                  >
                    {el.goodsList?.map((v) => {
                      return (
                        <Col key={v.goodsId}>
                          <DynamicProductCard
                            discountPrice={Number(v.discountPrice)}
                            goodsId={v.goodsId}
                            key={v.goodsId}
                            mainPicUrl={v.mainPicUrl}
                            picGroupNum={v.picGroupNum}
                            price={v.price}
                            visibleType={v.visibleType}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                )}
                recommendDesc={el.recommendDesc}
                seenNum={el.browsedNum}
                vendorId={el.goodsVendorId}
                vendorName={el.goodsVendorName}
              />
            );
          })}
          {
            (!renderList[currentTabKey]?.dynamicList || renderList[currentTabKey]?.dynamicList?.length === 0) && (
              <div className={styles.emptyDataWrap}>
                <EmptyStatus/>
              </div>
            )
          }
        </div>
      );
    }
  };

  return (
    <>
      <Navigation className={styles.navigation}/>
      <div className={styles.page}>
        <header
          className={styles.header}
          style={{
            background: `no-repeat center center url(${PUBLIC_IMAGE_URL}vendorBackground.png)`,
            backgroundSize: 'cover',
          }}
        >
          <div
            className={styles.headerContainer}
          >
            <div className={styles.vendorIcon}>
              <img src={vendorInfo.vendorLogo ? vendorInfo.vendorLogo : `${PUBLIC_IMAGE_URL}vendorIcon.png`}/>
            </div>
            <div>
              <div className={styles.vendorName}>
                {vendorInfo.vendorName}
              </div>
              <div className={styles.labelContainer}>
                {vendorInfo.labelList?.map((el, index) => {
                  return (
                    <div
                      className={styles.label}
                      key={nanoid()}
                      style={{
                        color: '#fff',
                        backgroundColor: `${ColorMap[index % 5]}`,
                      }}
                    >
                      {el}
                    </div>
                  );
                })}
                {vendorInfo.unionFlag ? <NoLackTag height="100%"/> : null}
              </div>
            </div>
          </div>
        </header>
        <Affix onChange={onAffixChange}>
          <div className={affixed ? `${styles.affixContainer} ${styles.affixedStyle}` : `${styles.affixContainer}`}>
            <Tabs
              className={styles.tabs}
              defaultActiveKey="cooperation"
              items={tabItems}
              onChange={onTabChange}
              tabBarGutter={16}
            />
            <Input.Search
              className={styles.searchInput}
              onChange={(e) => {
                console.log(e.target.value);
                store.sellerOuterNo = e.target.value;
              }}
              onSearch={onSearch}
              placeholder="请输入货号或商品名称"
            />
          </div>
        </Affix>
        <Spin spinning={pageLoading}>
          <div className={styles.filter}>
            {filters[currentTabKey]}
          </div>
          {pageLoading ? <BlankSegment/> : (
            <>
              <div className={styles.styleContainer}>
                {renderPage()}
              </div>
              <PaginationCus
                pageSize={conditions[currentTabKey].pageSize}
                store={paginationStore}
                totalCount={renderList[currentTabKey].totalCount}
                totalPageCount={renderList[currentTabKey].totalPageCount}
              />
            </>
          )}
        </Spin>

      </div>
      <RightSider store={rightSiderStore}/>
      <FooterLogo/>
      <Footer/>
    </>
  );
});

export default VendorDetail;
