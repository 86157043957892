import type { BaseData, PaginationData } from 'egenie-common';
import { request } from 'egenie-common';
import { action, observable, set } from 'mobx';
import React from 'react';
import { api, replacePath, scrollToAnchor } from '../../utils';
import { Filter, FilterStore } from '../components';
import PaginationStore from '../components/pagination/store';
import { RightSiderStore } from '../components/rightSider/store';
import type { MarketProps, ParentFtCategoryProps, RenderItem, StyleItem, TagsProps, VendorDynamicItem, VendorInfo } from './types';

export const ColorMap = [
  '#1978ff',
  '#f2270a',
  '#a419ff',
  '#02c190',
  '#ff9948',
];

export const getAllThirdCategory = (secondCategory) => {
  let arr = [];
  if (!secondCategory || secondCategory.length === 0) {
    return arr;
  }
  secondCategory.map((item) => {
    if (item.child) {
      arr = arr.concat(item.child);
    }
  });
  return arr;
};

export default class Store {
  @observable public sellerOuterNo = '';

  constructor() {
    this.init();
  }

  @observable public styleFilterStore = new FilterStore({
    filterItems: [
      {
        type: 'select',
        field: 'sidx',
        data: [
          {
            label: '综合排序',
            value: '',
          },
          {
            label: '上新',
            value: 'putAwayTime',
          },
          {
            label: '销量',
            value: 'salesNum',
          },
          {
            label: '价格由低到高',
            value: 'minWholePrice',
          },
          {
            label: '价格由高到低',
            value: 'maxWholePrice',
          },
        ],
        placeholder: '排序',
        value: '',
      },
      {
        type: 'treeSelect',
        label: '',
        field: 'ftCategoryIds',
        showSearch: true,
        allowClear: true,
        placeholder: '全部品类',
      },
      {
        type: 'select',
        mode: 'multiple',
        showSearch: true,
        label: '',
        field: 'style',
        allowClear: true,
        placeholder: '全部风格',
      },
      {
        type: 'select',
        mode: 'multiple',
        showSearch: true,
        label: '',
        field: 'applicableChannel',
        allowClear: true,
        placeholder: '主销渠道',
      },
      {
        type: 'select',
        mode: 'multiple',
        showSearch: true,
        label: '',
        field: 'applicableSeason',
        allowClear: true,
        placeholder: '适用季节',
      },
      {
        type: 'select',
        mode: 'multiple',
        showSearch: true,
        label: '',
        field: 'applicableGender',
        allowClear: true,
        placeholder: '适用性别',
        collapseItem: true,
      },
      {
        type: 'select',
        mode: 'multiple',
        showSearch: true,
        label: '',
        field: 'applicableAge',
        allowClear: true,
        placeholder: '适用年龄',
        collapseItem: true,
      },
      {
        type: 'inputNumberGroup',
        label: '',
        field: 'priceRange',
        minLength: 1,
        maxLength: 5,
        prefix: '￥',
        collapseItem: true,
      },
    ],
    handleSearch: () => {
      this.resetPager();
      this.queryStyle(this.styleFilterStore.params || []);
    },
  });

  @observable public dynamicFilterStore = new FilterStore({
    filterItems: [
      {
        type: 'select',
        field: 'type',
        data: [
          {
            label: '全部',
            value: 0,
          },
          {
            label: '公开',
            value: 1,
          },
          {
            label: '上新',
            value: 2,
          },
          {
            label: '定向',
            value: 4,
          },
        ],
        value: 0,
      },
    ],
    handleSearch: () => {
      this.resetPager();
      this.queryDynamic(this.dynamicFilterStore.params || []);
    },
  });

  @observable public affixed = false; // 是否已经吸顶，用来判断样式

  @observable public pageLoading = false;

  @observable public vendorId: number; // 档口id

  @observable public dynamicId: number | string; // 动态id

  @observable public currentTabKey: 'style' | 'dynamic' = 'style'; // 当前tabKey

  @observable public paginationStore = new PaginationStore({ parent: this }); // 分页store

  @observable public rightSiderStore = new RightSiderStore({ parent: this });

  @observable public tabs = [
    {
      title: '全部款式',
      key: 'style',
      filters: <Filter store={this.styleFilterStore}/>,
    },
    {
      title: '档口动态',
      key: 'dynamic',
      filters: <Filter store={this.dynamicFilterStore}/>,
    },
  ];

  @observable public category = []; // 类目

  // 请求条件
  @observable public conditions = {
    style: {
      current: 1,
      pageSize: 50,
    },
    dynamic: {
      current: 1,
      pageSize: 10,
    },
  };

  // list
  @observable public renderList: Record<'style' | 'dynamic', RenderItem> = {
    style: {
      totalCount: 0,
      totalPageCount: 1,
      styleList: [],
    },
    dynamic: {
      totalCount: 0,
      totalPageCount: 1,
      dynamicList: [],
    },
  };

  // 档口信息
  @observable public vendorInfo = {
    vendorName: '',
    vendorLogo: '',
    labelList: [],
    unionFlag: 0,
  };

  public filters = {
    style: <Filter store={this.styleFilterStore}/>,
    dynamic: <Filter store={this.dynamicFilterStore}/>,
  };

  private init = () => {
    this.parserParams();
    this.queryStyle(); // 请求全部款式
    this.queryVendorDetail();
    this.getParentFtCategory();
    this.queryDynamic();
    this.getDict();
    this.getGoodsMarket(); // 市场字典
  };

  // tab改变
  @action public onTabChange = (activeKey: 'style' | 'dynamic') => {
    this.currentTabKey = activeKey;
    this.setPager();
    this.backTop();
  };

  // 分页页数改变的回调
  @action public onShowSizeChange = (page: number, pageSize: number) => {
    Object.assign(this.conditions[this.currentTabKey], {
      current: page,
      pageSize,
    });

    this.backTop();
    this.onPageConditionChange();
  };

  // 分页改变回调
  @action public onPageChange = (page: number, pageSize: number) => {
    Object.assign(this.conditions[this.currentTabKey], {
      current: page,
      pageSize,
    });

    this.backTop();
    this.onPageConditionChange();
  };

  // 处理前端3级类目
  private dealFtCategory = (categoryList: ParentFtCategoryProps[]) => {
    return categoryList?.map((category) => {
      if (!category.child || category.child.length === 0) {
        return {
          label: category.ftCategoryName,
          value: category.id,
          children: [],
        };
      } else {
        return {
          label: category.ftCategoryName,
          value: category.id,
          children: this.dealFtCategory(category.child),
        };
      }
    });
  };

  // 获取品类
  @action
  public getParentFtCategory = async() => {
    const req = await request<BaseData<ParentFtCategoryProps[]>>({
      url: api.queryMoreCategory,
      method: 'get',
    });
    this.category = req.data;
    const ftCategoryIds = this.dealFtCategory(req.data);
    this.styleFilterStore.updateFilterItems({ ftCategoryIds });
  };

  // 处理所选择的品类
  @action
  public getFtCategory = (categoryId: number) => {
    let parentFtCategoryId: number,
      ftCategoryIds = [];
    const currentCategory = this.category?.find((v) => v.id === categoryId);
    if (currentCategory) {
      parentFtCategoryId = categoryId;
    } else {
      for (let i = 0; i < this.category.length; i++) {
        const secondList = this.category[i].child;
        const secondCategory = secondList?.find((v) => v.id === categoryId);
        if (secondCategory) {
          parentFtCategoryId = this.category[i].id;
          ftCategoryIds = [categoryId];
          break;
        } else {
          for (let j = 0; j < secondList.length; j++) {
            const thirdCategory = secondList[j].child?.find((v) => v.id === categoryId);
            if (thirdCategory) {
              parentFtCategoryId = this.category[i].id;
              ftCategoryIds = [categoryId];
              break;
            }
          }
        }
      }
    }
    return {
      parentFtCategoryId,
      ftCategoryIds,
    };
  };

  @action public getGoodsMarket = async() => {
    const req = await request<BaseData<MarketProps[]>>({
      url: api.getGoodsMarket,
      method: 'get',
    });

    // 给当前激活tab筛选项改变下拉值
    this.styleFilterStore.updateFilterItems({
      marketIds: req.data.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    });
  };

  // 请求风格、渠道、季节、
  @action public getDict = async() => {
    const res = await request<BaseData<TagsProps[]>>({ url: `${api.getTagGroup}?type=3` });
    this.styleFilterStore.updateFilterItems({ applicableSeason: this.handleDict(res.data, 10017) });
    this.styleFilterStore.updateFilterItems({ style: this.handleDict(res.data, 10001) });
    this.styleFilterStore.updateFilterItems({ applicableAge: this.handleDict(res.data, 100019) });
    this.styleFilterStore.updateFilterItems({ applicableChannel: this.handleDict(res.data, 10015) });
    this.styleFilterStore.updateFilterItems({ applicableGender: this.handleDict(res.data, 10018) });
  };

  // 处理字典
  private handleDict = (dictList: TagsProps[], groupId: number) => {
    return dictList.filter((item) => item.tagGroupId === groupId)[0].tags.map((item) => ({
      label: item.tagName,
      value: item.tagId,
    }));
  };

  // 回到顶部
  private backTop = (): void => {
    scrollToAnchor('root');
  };

  // 设置分页
  @action private setPager = () => {
    set(this.paginationStore, {
      current: this.conditions[this.currentTabKey].current,
      pageSize: this.conditions[this.currentTabKey].pageSize,
    });
  };

  // 获取条件
  private getCondition = (currentTabKey: 'style' | 'dynamic'): Record<string, string | number | string[] | number[]> => {
    const conditions = {
      ...this.conditions[currentTabKey],
      page: 1,
    };
    for (const key in conditions) {
      if (Object.prototype.hasOwnProperty.call(conditions, key)) {
        const element = conditions[key];
        if (key === 'current') {
          delete conditions.current;
          conditions.page = element;
        }
      }
    }
    return conditions;
  };

  // 请求全部款式
  @action private queryStyle = async(params?: Record<string, string | number | string[] | number[]>): Promise<void> => {
    this.pageLoading = true;
    const data = this.getCondition('style');

    if (params) {
      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          const values = params[key];
          if (key === 'sidx' && values === 'maxWholePrice') {
            Object.assign(data, {
              sidx: 'productPrice',
              sord: 'desc',
            });
            continue;
          }
          if (key === 'sidx' && values === 'minWholePrice') {
            Object.assign(data, {
              sidx: 'productPrice',
              sord: 'asc',
            });
            continue;
          }
          if (key === 'priceRange') {
            Object.assign(data, {
              minProductPrice: values[0],
              maxProductPrice: values[1],
            });
            continue;
          }
          if (key === 'ftCategoryIds') {
            const {
              parentFtCategoryId,
              ftCategoryIds,
            } = this.getFtCategory(Number(values));
            Object.assign(data, {
              parentFtCategoryId,
              ftCategoryIds,
            });
            continue;
          }
          data[key] = values;
        }
      }
    }

    const res: PaginationData<StyleItem> = await request({
      url: api.getPageGoods,
      method: 'post',
      data: {
        ...data,
        vendorId: this.vendorId,
        sellerOuterNo: this.sellerOuterNo,
      },
    });

    this.renderList.style.styleList = res.data.list;
    Object.assign(this.renderList.style, {
      totalCount: res?.data?.totalCount || 0,
      totalPageCount: res?.data?.totalPageCount || 1,
    });
    setTimeout(() => {
      this.pageLoading = false;
    }, 0);
  };

  // 请求款式详情
  @action private queryVendorDetail = async(): Promise<void> => {
    const res: BaseData<VendorInfo> = await request({ url: `${api.queryVendorDetail}?goodsVendorId=${this.vendorId}` });
    const {
      goodsVendorName,
      qualityLabelList,
      vendorLogo,
      marketLabelName,
      unionFlag,
    } = res.data;
    Object.assign(this.vendorInfo, {
      vendorName: goodsVendorName,
      vendorLogo,
      labelList: qualityLabelList || [],
      unionFlag,
    });

    if (marketLabelName) {
      this.vendorInfo.labelList.unshift(marketLabelName);
    }

    document.title = goodsVendorName || '';
  };

  // 请求档口动态
  @action private queryDynamic = async(params?: Record<string, string | number | string[] | number[]>): Promise<void> => {
    this.pageLoading = true;
    const data = this.getCondition('dynamic');
    if (!params) {
      data.goodsVendorDynamicId = this.dynamicId;
      data.type = 0;
    }

    if (params) {
      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          const values = params[key];
          data[key] = values;
        }
      }
    }
    const res: PaginationData<VendorDynamicItem> = await request({
      url: api.queryVendorDynamic,
      method: 'post',
      data: {
        ...data,
        goodsVendorId: this.vendorId,
        sellerOuterNo: this.sellerOuterNo,
      },
    });

    this.renderList.dynamic.dynamicList = res.data.list || [];
    Object.assign(this.renderList.dynamic, {
      totalCount: res?.data?.totalCount || 0,
      totalPageCount: res?.data?.totalPageCount || 1,
    });
    setTimeout(() => {
      this.pageLoading = false;
    }, 0);
  };

  // 请求路径参数
  private parserParams = (): void => {
    const path = window.location.href.split('?')[1];
    const params = new URLSearchParams(path);
    const vendorId = params.get('vendorId');
    const dynamicId = params.get('dynamicId');
    this.vendorId = Number(vendorId);
    this.dynamicId = dynamicId || '';
  };

  // 条件改变
  public onPageConditionChange = (): void => {
    if (this.currentTabKey === 'style') {
      this.queryStyle(this.styleFilterStore.params || []);
    } else {
      this.queryDynamic(this.dynamicFilterStore.params || []);
    }
  };

  // 吸顶状态改变
  public onAffixChange = (affixed: boolean) => {
    this.affixed = affixed;
  };

  // 跳转到档口详情
  public jumpToVendorDetail = (vendorId: number, dynamicId: number | string): void => {
    window.open(replacePath(`/egenie-ts-vogue/stallDetail/index?vendorId=${vendorId}&dynamicId=${dynamicId || ''}`));
  };

  // 跳转到款式详情
  public jumpToGoodsDetail = (goodsId: number, dynamicId?: number | string): void => {
    window.open(replacePath(`/egenie-ts-vogue/goodsDetail/index?goodsId=${goodsId}&dynamicId=${dynamicId || ''}`));
  };

  // 重置分页
  private resetPager = () => {
    set(this.paginationStore, { current: 1 });
    Object.assign(this.conditions[this.currentTabKey], { current: 1 });
  };

  @action
  public onSearch = (value: string) => {
    this.resetPager();
    if (this.currentTabKey === 'style') {
      this.styleFilterStore.params.current = 1;
      this.styleFilterStore.params.page = 1;
    } else {
      this.dynamicFilterStore.params.current = 1;
      this.dynamicFilterStore.params.page = 1;
    }
    this.onPageConditionChange();
  };
}
